import { Controller } from "@hotwired/stimulus"
import debounce from "debounce"

// Connects to data-controller="search-form"
export default class extends Controller {
  connect() {
    console.log("Connected: ", this.element);
  }

  initialize() {
    console.log("Initialize");
    this.submit = debounce(this.submit.bind(this), 300);
  }

  disconnect() {
    console.log("Disconnect");
  }

  submit(event) {
    this.element.requestSubmit();
  }
}
