import { Controller } from "@hotwired/stimulus";
import * as echarts from "echarts";

// Connects to data-controller="trends-chart"
export default class extends Controller {
  connect() {}

  show() {
    var $elem = document.querySelector("#trendsChart");
    if ($elem) {
      var myChart = echarts.init($elem);
      var option;

      let elastic = JSON.parse(this.data.get("elastic"));
      let splunk = JSON.parse(this.data.get("splunk"));
      let datadog = JSON.parse(this.data.get("datadog"));
      let snowflake = JSON.parse(this.data.get("snowflake"));
      let sumologic = JSON.parse(this.data.get("sumologic"));
      let zabbix = JSON.parse(this.data.get("zabbix"));
      let observability = JSON.parse(this.data.get("observability"));
      let clickhouse = JSON.parse(this.data.get("clickhouse"));
      let neo4j = JSON.parse(this.data.get("neo4j"));

      var animate = true;
      let queryParams = new URLSearchParams(window.location.search);
      if (queryParams.get("animate")) {
        animate = !(queryParams.get("animate") == 'false');
      }

      option = {
        animation: animate,
        tooltip: {
          trigger: "axis",
        },
        grid: {
          top: 50,
          right: 15,
          bottom: 50,
          left: 15,
          containLabel: true,
        },
        xAxis: [
          {
            boundaryGap: false,
            data: JSON.parse(this.data.get("weeks")),
          },
        ],
        yAxis: {
          type: 'value',
        },
        series: [
          {
            name: "Elastic",
            type: "line",
            smooth: true,
            data: elastic,
          },
          {
            name: "Splunk",
            type: "line",
            smooth: true,
            data: splunk,
          },
          {
            name: "Datadog",
            type: "line",
            smooth: true,
            data: datadog,
          },
          {
            name: "Snowflake",
            type: "line",
            smooth: true,
            data: snowflake,
          },
          {
            name: "Sumologic",
            type: "line",
            smooth: true,
            data: sumologic,
          },
          {
            name: "Zabbix",
            type: "line",
            smooth: true,
            data: zabbix,
          },
          {
            name: "Observability",
            type: "line",
            smooth: true,
            data: observability,
          },
          {
            name: "Clickhouse",
            type: "line",
            smooth: true,
            data: clickhouse,
          },
          {
            name: "Neo4j",
            type: "line",
            smooth: true,
            data: neo4j,
          },
        ],
      };

      option && myChart.setOption(option);
    }
  }
}
