import { Controller } from "@hotwired/stimulus";
import * as echarts from "echarts";

// Connects to data-controller="timings"
export default class extends Controller {

  initialize() {
  }

  connect() {
    //alert(JSON.parse(this.data.get("categories")));
    //alert(JSON.parse(this.data.get("result-latency")));
    //alert(JSON.parse(this.data.get("result-cpu-load")));
    //alert(JSON.parse(this.data.get("job-latency")));
    //alert(JSON.parse(this.data.get("job-cpu-load")));
    //alert('hi')
    //show();
  }

  show() {
    var $timings = document.querySelector("#timings");
    if ($timings) {
      //var myChart = echarts.init($timings, 'dark');
      var myChart = echarts.init($timings);
      var option;
      option = {
        tooltip: {
          trigger: "axis",
        },
        legend: {
          top: 5,
          data: ["Result Latency (sec)", "Result CPU Load (1 min)", "Job Latency (sec)", "Job CPU Load (1 min)"],
        },
        grid: {
          containLabel: true,
          left: 15,
          top: 35,
          right: 15,
          bottom: 15,
        },
        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },
        xAxis: {
          type: 'category',
          data: JSON.parse(this.data.get("categories"))
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            name: "Result Latency (sec)",
            data: JSON.parse(this.data.get("result-latency")),
            type: "line",
            smooth: true,
            //lineStyle: {color: '#6DC3C1'}
          },
          {
            name: "Result CPU Load (1 min)",
            data: JSON.parse(this.data.get("result-cpu-load")),
            type: "line",
            smooth: true,
            //lineStyle: {color: '#1E73BE'}
          },
          {
            name: "Job Latency (sec)",
            data: JSON.parse(this.data.get("job-latency")),
            type: "line",
            smooth: true,
            //lineStyle: {color: '#C850B0'}
          },
          {
            name: "Job CPU Load (1 min)",
            data: JSON.parse(this.data.get("job-cpu-load")),
            type: "line",
            smooth: true,
            //lineStyle: {color: '#660B60'}
          },
        ],
      };
    }
    option && myChart.setOption(option);
  }
}
