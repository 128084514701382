import { Controller } from "@hotwired/stimulus";
import * as echarts from "echarts";

// Connects to data-controller="cloud-chart"
export default class extends Controller {
  connect() {}

  show() {
    var $elem = document.querySelector("#cloudChart");
    if ($elem) {
      var myChart = echarts.init($elem);
      var option;

      var company = this.data.get("company");
      var report = this.data.get("report");
      var animate = this.data.get("animate");
      var fontSize = 16;

      if (window.location.href.includes("/internal/")) {
        fontSize = 32;
      }

      option = {
        animation: animate,
        tooltip: {
          trigger: "item",
        },
        series: [
          {
            type: "pie",
            top: '-10%',
            center: ['50%', '60%'],
            avoidLabelOverlap: true,
            itemStyle: {
              borderRadius: 10,
              borderColor: "#fff",
              borderWidth: 2,
            },
            label: {
              show: true,
              fontSize: fontSize,
            },
            labelLine: {
              show: true,
            },
            data: JSON.parse(this.data.get("groups")),
          },
        ],
      };

      myChart.on("click", function (params) {
        var pointInPixel = [params.offsetX, params.offsetY];
        window.location.href =
          "/" + company + "/" + report + "/job-matches?tag=" + params.name;
      });

      option && myChart.setOption(option);
    }
  }
}
