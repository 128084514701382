import { Controller } from "@hotwired/stimulus";
import * as echarts from "echarts";

// Connects to data-controller="technologies-chart"
export default class extends Controller {
  connect() {}
  show() {
    var $elem = document.querySelector("#technologiesChart");
    if ($elem) {
      var myChart = echarts.init($elem);
      var option;

      var company = this.data.get("company");
      var report = this.data.get("report");
      var animate = this.data.get("animate");
      var fontSize = 16;

      if (window.location.href.includes("/internal/")) {
        fontSize = 32;
      }

      option = {
        animation: animate,
        tooltip: {},
        grid: {
          containLabel: true,
          left: 35,
          top: 25,
          right: 25,
          bottom: 25,
        },
        xAxis: {
          type: "value",
          axisLabel: {
            fontSize: fontSize,
          },
        },
        yAxis: {
          type: "category",
          data: JSON.parse(this.data.get("y")),
          axisLabel: {
            fontSize: fontSize,
          },
        },
        series: [
          {
            data: JSON.parse(this.data.get("x")),
            type: "bar",
            label: {
              show: true,
              position: 'right',
            },
          },
        ],
      };

      myChart.on("click", function (params) {
        var pointInPixel = [params.offsetX, params.offsetY];
        //console.log(params.name);
        //console.log(params.seriesName);
        //console.log(params.seriesIndex);
        //console.log(params);
        window.location.href =
          "/" + company + "/" + report + "/job-matches?tag=" + params.name;
      });

      option && myChart.setOption(option);
    }
  }
}
