import { Controller } from "@hotwired/stimulus";
import * as echarts from "echarts";

// Connects to data-controller="technologies-right"
export default class extends Controller {
  connect() {}
  show() {
    var $elem = document.querySelector("#technologiesRight");
    if ($elem) {
      var myChart = echarts.init($elem);
      var option;
      var company = this.data.get("company");
      var report = this.data.get("report");

      option = {
        animation: false,
        grid: {
          containLabel: true,
          left: 150,
          top: 25,
          right: 25,
          bottom: 25,
        },
        tooltip: {},
        yAxis: {
          type: "category",
          data: JSON.parse(this.data.get("y")),
          axisLabel: {
            fontSize: 30,
          },
        },
        xAxis: {
          type: "value",
          axisLabel: {
            fontSize: 30,
          },
        },
        series: [
          {
            data: JSON.parse(this.data.get("x")),
            type: "bar",
            label: {
              fontSize: 30,
              show: true,
              position: 'right',
            },
          },
        ],
      };

      option && myChart.setOption(option);
    }
  }
}
