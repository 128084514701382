import { Controller } from "@hotwired/stimulus";
import * as echarts from "echarts";

// Connects to data-controller="linkedin-chart"
export default class extends Controller {
  connect() {}

  show() {
    var $elem = document.querySelector("#linkedinChart");
    if ($elem) {
      var myChart = echarts.init($elem);
      var option;

      let count = JSON.parse(this.data.get("linkedin"));
      let diff = JSON.parse(this.data.get("diff"));
      let diff_min = Math.min.apply(Math, diff);
      let diff_max = Math.max.apply(Math, diff);
      let max = Math.max.apply(Math, [Math.abs(diff_min), Math.abs(diff_max)])

      var animate = true;
      let queryParams = new URLSearchParams(window.location.search);
      if (queryParams.get("animate")) {
        animate = !(queryParams.get("animate") == 'false');
      }

      option = {
        animation: animate,
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: ["LinkedIn Followers", "Change"],
        },
        grid: {
          top: 50,
          right: 15,
          bottom: 50,
          left: 15,
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            data: JSON.parse(this.data.get("days")),
          },
        ],
        yAxis: [
          {
            type: "value",
            min: Math.min.apply(Math, count) - 20,
            max: Math.max.apply(Math, count) + 20,
          },
          {
            type: 'value',
            show: false,
            position : 'right',
            min: 0 - max - 20,
            max: 0 + max + 20,
          },
        ],
        series: [
          {
            name: "LinkedIn Followers",
            type: "line",
            smooth: true,
            data: count,
          },
          {
            name: "Change",
            type: "bar",
            barWidth: 10,
            yAxisIndex : 1,
            data: diff,
          },
        ],
      };

      option && myChart.setOption(option);
    }
  }
}
